import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from "../images/logo.svg"

const ThankYouPage = () => (
  <Layout>
    <SEO title="Grazie!" />
    <section className="section-intro">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-5 text-white">
            <a className="navbar-brand" href="/" rel="home" itemProp="url">
              <img
                width="125"
                height="76"
                src={logo}
                alt="Logo O3ZY"
                itemProp="logo"
                style={{ marginTop: `24px`, marginBottom: `80px` }}
              />
            </a>
            <h1>
              OZY: sanifica
              <br />
              gli ambienti
              <br />
              con l'ozono
            </h1>
            <p>
              OZY è un sanificatore con processo di erogazione e misurazione
              dell'ozono brevettato. Efficace contro virus e patogeni.
            </p>
            <p className="font-weight-bold text-uppercase text-gray small">
              Swiss made: garanzia di sicurezza e qualità.
            </p>
          </div>

          <div className="col-12 col-lg-5 offset-lg-2" id="form">
            <div>
              <h1>Grazie!</h1>
              <p>Ti ricontatteremo al più presto</p>
              <Link className="btn btn-secondary" to="https://o3zy.com/">
                Vai al sito
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default ThankYouPage
